<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <!-- Browser States Card -->
      <b-col lg="4" md="6">
        <my-results-browser-states :general="data.generalInfo" />
      </b-col>
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <b-col lg="4" md="6">
        <my-results-goal-overview :data="data.goalOverview" />
      </b-col>
      <!--/ Goal Overview Card -->

      <!-- Developer Meetup Card -->
      <b-col lg="4" md="6">
        <my-results-meetup :data="data.meetup" />
      </b-col>
      <!--/ Developer Meetup Card -->
    </b-row>

    <b-row class="match-height">
      <!-- <b-col xl="4" md="6">
        <my-results-medal :data="data.congratulations" />
      </b-col> -->
      <b-col xl="12" md="12">
        <my-results-statistics :data="data.statisticsItems" :botsRunning="data.botsRunning" />
      </b-col>
    </b-row>

    <b-modal id="modal-my-results" :title="modalData.name" centered ok-only ok-title="Confirmar retirada" @ok="handleOk"
      no-close-on-backdrop>
      <div>
        <div class="d-flex justify-content-between">
          {{ $t("Valor total alocado") }}:
          <span class="text-warning">
            {{
              moneyFormat(modalData.initialValue)
            }}</span>
        </div>
        <div class="d-flex justify-content-between pt-1">
          {{ $t("Lucro total registrado") }}:
          <span class="text-success">+
            {{
              moneyFormat(modalData.totalProfit)
            }}</span>
        </div>
        <div class="d-flex justify-content-between pt-1">
          {{ $t("Valor total retirado") }}:
          <span class="text-danger">-
            {{
              moneyFormat(modalData.totalWhitdraw)
            }}</span>
        </div>
        <div class="d-flex justify-content-between pt-1">
          {{ $t("Valor pago em licença") }}:
          <span class="text-danger">-
            {{
              moneyFormat(modalData.valueInFees)
            }}</span>
        </div>
        <div class="d-flex justify-content-between pt-1">
          {{ $t("Valor disponível") }}:
          <span class="text-info">
            {{
              moneyFormat(modalData.balance)
            }}</span>
        </div>
      </div>
      <hr />
      <div>
        <div class="mb-1">
          {{ $t("Quanto do valor disponível você pretende retirar?") }}
        </div>
        <slider-modal-my-results :balance="parseInt(modalData.balance)" :profit="parseInt(
          modalData.balance -
          modalData.initialValue -
          modalData.totalWhitdraw -
          modalData.valueInFees
        )
          " :percentWithdraw="percentWithdraw" @changeWhitdrawValue="(val) => (whitdrawValue = val)" />
        <div class="my-1 text-center">
          <div class="d-flex justify-content-center mt-1">
            <b-button variant="outline-primary" size="sm" class="mr-1" @click="() => (percentWithdraw = 1)">{{
              $t(`Retirar
              tudo`) }}</b-button>
            <b-button variant="outline-primary" size="sm" class="mr-1" @click="() => (percentWithdraw = 0.5)">{{
              $t("Retirar 50%") }}</b-button>
            <b-button variant="outline-primary" size="sm" @click="() => (percentWithdraw = 0.25)">{{ $t("Retirar 25%")
              }}</b-button>
          </div>
          <b-button variant="primary" size="sm" class="mt-1" @click="() => (percentWithdraw = 99)">{{ $t(`Retirar
            somente
            o lucro`) }}</b-button>
        </div>
        <div class="mt-1 text-center">
          {{ $t("Taxa de retirada") }}:
          <span class="text-danger">{{ calcFees }}%</span>
        </div>
        <div class="text-center">
          {{ $t("Será creditado em seu saldo") }}:
          <span class="text-info">{{
            moneyFormat(whitdrawValue - whitdrawValue * (calcFees / 100))
          }}</span>
        </div>
      </div>
      <alert :text="textAlert" :color="colorAlert" :showAlert="showAlert"
        @changeVariableAlert="(val) => (showAlert = val)" class="mt-1" />
    </b-modal>

    <b-modal id="modal-management-statement" title="Comunicado del Departamento de Expansión" centered hide-footer
      no-close-on-backdrop>
      <img :src="require('../../../../public/marketing/images/event_07.12.png')" alt="Comunicado da Direção"
        class="img-fluid" />
      <div class="text-center my-1">
        <b-button variant="primary" href="https://forms.gle/w2kHpHtdF9GocVy96"
          target="_blank">
          Haga clic y confirme su entrada
        </b-button>
      </div>
    </b-modal>

  </section>
</template>

<script>
import { BRow, BCol, BModal, VBModal } from "bootstrap-vue";

import MyResultsMedal from "./MyResultsMedal.vue";
import MyResultsStatistics from "./MyResultsStatistics.vue";
import MyResultsMeetup from "./MyResultsMeetup.vue";
import MyResultsBrowserStates from "./MyResultsBrowserStates.vue";
import MyResultsGoalOverview from "./MyResultsGoalOverview.vue";
import IndividuallyTradingCard from "./IndividuallyTradingCard.vue";
import { mapGetters } from "vuex";
import SliderModalMyResults from "./SliderModalMyResults.vue";
import CopyTrades from "../trading-robot/copy-trades/CopyTrades.vue";

export default {
  components: {
    BRow,
    BCol,

    MyResultsMedal,
    MyResultsStatistics,
    MyResultsMeetup,
    MyResultsBrowserStates,
    MyResultsGoalOverview,
    IndividuallyTradingCard,

    SliderModalMyResults,

    CopyTrades,

    BModal,
    VBModal
  },
  computed: {
    ...mapGetters(["returnUser"]),
    calcFees() {
      return this.whitdrawValue > 0
        ? this.whitdrawValue <=
          this.modalData.balance -
          this.modalData.initialValue -
          this.modalData.totalWhitdraw -
          this.modalData.valueInFees
          ? 5
          : 60
        : 0;
    },
  },
  data() {
    return {
      showAlert: false,
      textAlert: "",
      colorAlert: "warning",
      data: {},
      modalData: {},
      whitdrawValue: 0,
      percentWithdraw: 0,
      referenceBonus: 0,
      unilevelBonusL2: 0,
      unilevelBonusL3: 0,
      unilevelBonusL4: 0,
      unilevelBonusL5: 0,
      unilevelBonusL6: 0,
      binaryBonus: 0,
      blockedBonus: 0,
      loading: true,
      myRobots: null,
      openModal: false,
    };
  },
  mounted() {
    this.initialData();
    this.myRobotsCopyTrade();
    this.$bvModal.show("modal-management-statement");
  },
  methods: {
    myRobotsCopyTrade() {
      this.$store.dispatch("dashboardMyResults").then((response) => {
        this.myRobots = parseInt(response.botsRunning);
        this.loading = false;
      });
      this.loadMyResults();
    },
    initialData() {
      this.referenceBonus = this.returnUser.referenceBonus
        ? this.returnUser.referenceBonus
        : 0;
      this.unilevelBonusL2 = this.returnUser.unilevelBonusL2
        ? this.returnUser.unilevelBonusL2
        : 0;
      this.unilevelBonusL3 = this.returnUser.unilevelBonusL3
        ? this.returnUser.unilevelBonusL3
        : 0;
      this.unilevelBonusL4 = this.returnUser.unilevelBonusL4
        ? this.returnUser.unilevelBonusL4
        : 0;
      this.unilevelBonusL5 = this.returnUser.unilevelBonusL5
        ? this.returnUser.unilevelBonusL5
        : 0;
      this.binaryBonus = this.returnUser.binaryBonus
        ? this.returnUser.binaryBonus
        : 0;
      this.blockedBonus = this.returnUser.blockedBonus
        ? this.returnUser.blockedBonus
        : 0;
      this.whitdrawValue = 0;
      this.percentWithdraw = 0;
      this.data = {
        generalInfo: null,
        congratulations: {
          name: "",
          patrimony: 0,
        },
        botsRunning: 0,
        statisticsItems: [
          {
            icon: "ActivityIcon",
            color: "light-primary",
            value: 0,
            subtitle: "Trades",
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "TrendingUpIcon",
            color: "light-info",
            value: 0,
            subtitle: "Com lucro",
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "TrendingDownIcon",
            color: "light-danger",
            value: 0,
            subtitle: "Com prejuízo",
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "DollarSignIcon",
            color: "light-success",
            value: 0,
            subtitle: "Resultado",
            customClass: "",
          },
        ],
        meetup: {
          mediaData: [
            {
              avatar: "DollarSignIcon",
              title: this.moneyFormat(this.referenceBonus),
              subtitle: "recebido de bônus dos indicados diretos",
              color: "success",
            },
            {
              avatar: "DollarSignIcon",
              title: this.moneyFormat(
                this.unilevelBonusL2 +
                this.unilevelBonusL3 +
                this.unilevelBonusL4 +
                this.unilevelBonusL5
              ),
              subtitle:
                "recebido de bônus dos indicados indiretos",
              color: "success",
            },
            {
              avatar: "DollarSignIcon",
              title: this.moneyFormat(
                this.binaryBonus
              ),
              subtitle:
                "recebido de bônus da árvore binária",
              color: "success",
            },
            {
              avatar: "DollarSignIcon",
              title: this.moneyFormat(this.blockedBonus),
              subtitle: "bônus bloqueado",
              color: "danger",
            },
          ],
          avatars: [
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
              fullName: "Billy Hopkins",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
              fullName: "Amy Carson",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
              fullName: "Brandon Miles",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
              fullName: "Daisy Weber",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
              fullName: "Jenny Looper",
            },
          ],
        },
        goalOverview: {
          initialValue: 0,
          licenseValue: 0,
          bonus: 0,
          totalProfit: 0,
          progressValue: 0,
          series: [0],
        },
        robots: [],
      };
    },
    loadMyResults() {
      this.data.congratulations.name = this.returnUser.username;
      this.$store.dispatch("dashboardMyResults").then((response) => {

        this.data.generalInfo = response
        // delete this.data.generalInfo.arrayRobots

        this.data.goalOverview.initialValue = response.initialValue
        this.data.goalOverview.licenseValue = response.licenseValue
        this.data.goalOverview.bonus = response.bonus
        this.data.goalOverview.totalProfit = response.totalProfit
        this.data.goalOverview.progressValue = parseFloat((response.bonus / response.limitBonus) * 100).toFixed(2)
        let progressIncome = 0
        response.arrayRobots.map((item) => {
          progressIncome += item.limitIncome > 0 ? parseFloat(item.totalProfit + item.bonus * 0.2) : 0
        })
        this.data.goalOverview.series = [parseFloat((progressIncome / response.limitIncome) * 100).toFixed(2)]
        this.data.statisticsItems[0].value = response.totalTrades;
        this.data.statisticsItems[1].value = response.tradesGain;
        this.data.statisticsItems[2].value = response.tradesLose;
        this.data.statisticsItems[3].value = this.moneyFormat(response.totalProfit);
      });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.handleSubmit();
    },
    withdraw(element) {
      const data = {
        robotId: element.item._id,
        value: element.type === 'bonus' ? element.item.bonus - element.item.totalWhitdraw : element.item.totalProfit - element.item.totalIncomeWithdrawal,
        type: element.type
      };
      if (data.value > 0) {
        this.$store
          .dispatch("withdrawValueTrading", data)
          .then((resp) => {
            this.$store.dispatch(
              "changeBalance",
              data.value
            );
            this.showAlert = true;
            this.textAlert =
              "Retirada de valor do robô realizada com sucesso";
            this.colorAlert = "success";
            this.initialData();
            this.loadMyResults();
          })
          .catch((error) => {
            this.showAlert = true;
            this.textAlert = "Falha ao retirar valor";
            this.colorAlert = "danger";
          });
      } else {
        this.showAlert = true;
        this.textAlert = "Valor para retirada inválido.";
        this.colorAlert = "danger";
      }
    },
    openModalMyResults(val) {
      this.modalData = val;
      this.whitdrawValue = 0;
      this.percentWithdraw = 0;
      this.$bvModal.show("modal-my-results");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
</style>
